// paper & background
$paper: #ffffff;

// primary
$primaryLight: #EEF2F6;
$primaryMain: #3A658D;
$primaryDark: #4682B3;
$primary200: #4682B3;
$primary800: #4682B3;

// secondary
$secondaryLight: #c8d9e7;
$secondaryMain: #4682B3;
$secondaryDark: #4682B3;
$secondary200: #3A658D;
$secondary800: #3A658D;

// success Colors
$successLight: #9bf4b4;
$success200: #2fe061;
$successMain: #047b42;
$successDark: #033e1c;

// error
$errorLight: #b55f5f;
$errorMain: #d31c1c;
$errorDark: #f40707;

// orange
$orangeLight: #e07f39;
$orangeMain: #a07638;
$orangeDark: #FF6900;

// warning
$warningLight: #ffe600;
$warningMain: #d5a821;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

//-----------------------|| JAVASCRIPT ||-----------------------//

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;
}
